import React from "react";
import {Header} from "./components/header/Header";
import {GeneralData} from "./interfaces/GeneralData";
import pr from "./config/lorealprofessionalpr.com.json";
import rd from "./config/lorealppdrd.com.json";
import {Brands} from "./components/brands/Brands";
import {Footer} from "./components/footer/Footer";

const TermsAndConditions: React.FC = () => {
    const project = process.env.REACT_APP_PROJECT_NAME;
    const json: GeneralData | any = project === 'pr' ? pr : project === 'rd' ? rd : null;
    // @ts-ignore
    return (
        <div>
            <Header data={json.header}/>
            <div className="w-2/3 m-auto text-sm">
                <h2 className="text-2xl"><strong>Políticas de Privacidad</strong></h2>
                <br/>
                L’Oréal tiene la vocación de ser una empresa ejemplar que contribuye a la creación de un mundo de
                belleza. Le otorgamos un gran valor a la honestidad y a la transparencia, y aspiramos a construir con
                nuestros clientes relaciones sólidas y duraderas, basadas en la confianza y el interés mutuo. De acuerdo
                con esta filosofía, la protección de tus datos personales es de vital importancia para nosotros, por lo
                que por la presente política de protección de datos personales te informamos cómo recopilamos y tratamos
                dichos datos.

                Esta Política de Privacidad establece las bases sobre las que L´Oréal Caribe, Inc. (en adelante,
                “L’Oreal” o “Nosotros”) con oficinas principales en T-Mobile Tower, San Patricio Village, Suite 1700 B7
                Calle Tabonuco, Guaynabo PR 00968, corporación que actualmente opera en Puerto Rico, trata sus datos de
                carácter personal cuando nos los facilite durante su experiencia a través de la página web <a
                href="https://www.lorealprofesionalpr.com/">https://www.lorealprofesionalpr.com/</a> (en adelante, “la
                Web”).

                Igualmente, le recordamos que cada vez que al usar la Web, nos facilite, o sea necesario que accedamos a
                cualquier tipo de información que por sus características nos permita identificarle, como su nombre y
                apellidos, e-mail, direcciones de facturación o envío, número de teléfono, tipo de dispositivo o número
                de tarjeta de débito o crédito, etc. (en adelante, “Datos Personales”), ya sea para navegar por la misma
                o hacer uso de sus servicios o funcionalidades, estará bajo la aplicación de esta Política de
                Privacidad, junto con los Términos de Uso de la Web, y otros documentos referenciados en las mismas
                vigentes en cada momento, debiendo revisar dichos textos para comprobar que está conforme con ellos.
                Esta Política de Privacidad y nuestros Términos de Uso podrían ser modificados. Es su responsabilidad
                leerlos periódicamente, ya que resultarán aplicables aquellos que se encuentren vigentes en el momento
                de uso de la Web.
                <br/>
                <br/>
                <strong>NUESTRO COMPROMISO DE PRIVACIDAD</strong>
                <br/>
                <br/>
                1) Respetamos su privacidad y sus elecciones.

                2) Nos aseguramos de que la privacidad y la seguridad estén incorporadas en todo lo que hacemos.

                3) No te enviamos comunicaciones de marketing a menos que nos lo solicites. Puedes cambiar de opinión en
                cualquier momento.

                4) Nunca ofrecemos ni vendemos sus datos.

                5) Nos comprometemos a mantener sus datos seguros y protegidos. Esto incluye solo trabajar con socios de
                confianza.

                6) Nos comprometemos a ser abiertos y transparentes sobre cómo usamos sus datos.

                7) No utilizamos sus datos de formas que no le hayamos informado.

                8) Respetamos sus derechos y siempre tratamos de acomodar sus solicitudes en la medida de lo posible, de
                acuerdo con nuestras propias responsabilidades legales y operativas.

                <br/>

                Para obtener más información acerca de nuestras prácticas de privacidad, a continuación, establecemos
                qué tipos de datos personales podemos recabar o mantener sobre usted, cómo podemos usarlos, con quién
                podemos compartirlos, cómo los protegemos, y cómo puede ejercitar sus derechos respecto de dichos datos.

                Cuando comparte sus Datos Personales con nosotros o cuando recabamos datos personales sobre usted, los
                usamos de acuerdo con esta Política de Privacidad (en adelante, la “Política”). Por favor, lea esta
                información cuidadosamente. Si tiene alguna pregunta o inquietud sobre sus datos personales, contáctenos
                por <a href="mailto:DMCA@loreal.com">DMCA@loreal.com</a>.
                <br/>
                <br/>
                <strong>¿QUIÉNES SOMOS?</strong>
                <br/>
                <br/>
                L´Oréal Caribe, Inc., con oficinas principales en T-Mobile Tower, San Patricio Village, Suite 1700 B7
                Calle Tabonuco, Guaynabo PR 00968, es responsable de los datos personales que usted comparte con
                nosotros. Cuando decimos "L'Oréal", "nosotros" o "nuestro", nos referimos a L´Oréal Caribe, Inc. L'Oreal
                es el responsable del tratamiento a los efectos de las leyes de protección de datos aplicables.

                <br/>
                <br/>

                <strong>DATOS PERSONALES</strong>

                <br/>
                <br/>

                Datos Personales hace referencia a cualquier información o datos que pueda identificarlo directamente
                (por ejemplo, su nombre o apellidos) o indirectamente (por ejemplo, su licencia de conducir o
                pasaporte). Los Datos Personales incluyen información tal como el correo electrónico, direcciones
                postales particulares, teléfono móvil, nombres de usuario, imágenes de perfil, preferencias personales,
                contenido generado por el usuario, información financiera e información de asistencia social, entre
                otros. También podría incluir identificadores numéricos únicos como la dirección IP de su ordenador o la
                dirección MAC de su dispositivo móvil, así como también la información que obtenemos a través de
                cookies. Esta Política cubre todos los datos personales recopilados y utilizados por L'Oréal.
                <br/><br/>
                <strong>RECOGIDA DE DATOS PERSONALES Y FINALIDADES DEL TRATAMIENTO</strong>
                <br/><br/>
                Recuerde que antes de empezar a utilizar cualquiera de nuestros servicios o funcionalidades, deberá leer
                esta Política, así como los Términos de Uso de la sección específica que verse sobre ese servicio o
                funcionalidad. En dicha sección podrá ver si hay alguna condición particular para su uso, o si se
                requiere un tratamiento específico de sus Datos Personales. El hecho de no facilitar cierta información
                señalada como obligatoria, puede conllevar que no sea posible gestionar su registro como usuario o el
                uso de determinadas funcionalidades o servicios disponibles a través de <a
                href="https://www.lorealprofesionalpr.com/">https://www.lorealprofesionalpr.com/</a>

                Por el presente, el usuario (usted) garantiza que los Datos Personales proporcionados son ciertos y
                exactos y se compromete a notificar cualquier cambio o modificación de los mismos.

                Cualquier pérdida o daño causado a la Web, a Nosotros o a cualquier tercero mediante la comunicación de
                información errónea, inexacta o incompleta en los formularios de registro será responsabilidad exclusiva
                del usuario.
                <br/><br/>
                <strong>¿Qué datos suyos recolectamos? </strong>
                <br/><br/>
                L'Oréal cree que usted, el consumidor, es el corazón de
                lo que hacemos. Nos encanta saber de usted, aprender sobre usted y crear y entregar productos que
                disfrute. Adicionalmente, sabemos que a muchos de ustedes les encanta hablar con nosotros. Debido a
                esto, hay muchas maneras en que puede compartir sus datos personales con nosotros, y que podríamos
                recolectarlos.

                Podemos recibir sus datos a través de nuestros sitios web, formularios, aplicaciones, dispositivos,
                productos de L'Oréal o páginas de marcas en las redes sociales, entre otros. En algunos casos, usted nos
                facilita sus Datos Personales directamente (por ejemplo, cuando crea una cuenta o cuando se pone en
                contacto con nosotros), en otros casos los recabamos nosotros (por ejemplo, usando <em>cookies </em>para
                comprender cómo usa nuestros sitios web) / apps) o, en otras ocasiones, recibimos sus datos de otros
                terceros, incluidas otras entidades afiliadas a L'Oréal.

                <br/>
                <br/>
                Encontrarás más detalles en la tabla a continuación, con los epígrafes siguientes:
                <br/>
                <br/>
                <ol>
                    <li><p><strong>¿Con qué finalidades podemos usar sus Datos Personales?</strong> Esta columna explica
                        qué podemos hacer con sus Datos Personales y los propósitos para recopilarlos.</p></li>
                    <li><p><strong>¿Cuál es la base legal para usar sus Datos Personales?</strong> Esta columna explica
                        la razón por la que podemos usar sus Datos Personales.</p></li>
                    <li><p><strong>¿Qué datos personales podemos tener sobre usted?</strong> Esta columna explica qué
                        tipos de Datos Personales suyos podemos tratar según el escenario.</p></li>
                    <li><p><strong>¿En qué escenario pueden recolectarse sus Datos Personales?</strong> Esta columna
                        explica en qué actividad o escenario está involucrado cuando usamos o recabamos sus Datos
                        Personales. Por ejemplo, si se registra en una promoción o navega por un sitio web / aplicación.
                    </p></li>
                    <li><p><strong>¿Quién/Quiénes pueden tener acceso a sus Datos Personales?</strong> Esta columna
                        explica qué terceros ajenos a L’Oréal acceden a sus datos de carácter personal como prestadores
                        de servicios de L’Oréal y qué cesiones de datos se llevan a cabo. Revise la sección “¿Quién
                        puede acceder a sus datos personales?” de esta Política que encontrará debajo de la tabla para
                        obtener mayor información sobre qué terceros pueden tener acceso sus Datos Personales y qué
                        cesiones de datos se llevan a cabo.</p></li>
                </ol>
                La base legal para el tratamiento de sus Datos Personales puede ser:
                <ul>
                    <li><p>Su consentimiento;</p></li>
                    <li><p>Nuestro interés legítimo, que puede ser: realización de estadísticas: para ayudarnos a
                        comprender mejor sus necesidades y expectativas y, por lo tanto, mejorar nuestros servicios,
                        sitios web / aplicaciones / dispositivos, productos y marcas; permitir el funcionamiento de
                        nuestro sitio web / aplicaciones a través de <em>cookies </em>técnicas y funcionales: mantener
                        nuestras herramientas (sitios web / aplicaciones / dispositivos) a salvo y seguras y garantizar
                        que funcionen correctamente y mejoren continuamente; elaboración de perfiles; y ofrecerle
                        nuestro servicio de atención al consumidor.</p></li>
                    <li><p>La ejecución de un contrato: realizar los servicios que nos solicita.</p></li>
                    <li><p>Cumplimiento de obligaciones legales que conllevan un tratamiento de datos personales. Cuando
                        recolectamos sus Datos Personales, indicamos los campos obligatorios a través de asteriscos.
                        Algunos de los datos que le solicitamos son necesarios para: cumplir nuestro contrato con usted;
                        proporcionarle el servicio que solicitó (por ejemplo, para proporcionarle un boletín de noticias
                        o <em>newsletter</em>); o cumplir con los requisitos legales (por ejemplo, facturación).</p>
                    </li>
                </ul>
                <table>
                    <tbody>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><em>Finalidad del tratamiento</em></p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><em>¿Cuál es la base legal para
                            procesar sus Datos Personales?</em></p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><em>¿Qué Datos Personales podemos
                            tener sobre usted?</em></p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><em>¿En qué escenario pueden recabarse
                            sus Datos Personales?</em></p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><em>¿Quién/Quiénes pueden tener acceso
                            a sus Datos Personales?</em></p></td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Envío de comunicaciones
                            comerciales y promocionales que</strong> <strong>puede incluir:</strong>

                            - Envío de comunicaciones de marketing.

                            - Invitaciones a eventos.

                            - Envío de muestras de producto.

                            - Promoción de nuestros productos

                            - Ofrecer servicios personalizados basados en sus características de belleza;</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><br/>

                            <strong>Consentimiento</strong></p></td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Nombre y apellidos;</p></li>
                                    <li><p>Género;</p></li>
                                    <li><p>Dirección de correo electrónico;</p></li>
                                    <li><p>Dirección postal;</p></li>
                                    <li><p>Número de teléfono;</p></li>
                                    <li><p>Foto;</p></li>
                                    <li><p>Fecha de nacimiento o rango de edad;</p></li>
                                    <li><p>ID,</p></li>
                                    <li><p>Descripción personal o preferencias;</p></li>
                                    <li><p>Perfil de redes sociales (cuando uses el inicio de sesión social o compartas
                                        esta información personal);</p></li>
                                    <li><p>Otra información que comparta con nosotros sobre usted (por ejemplo, fotos o
                                        reseñas, o preguntas a través de la función de chat disponible en algunos sitios
                                        web / aplicaciones o participando en un concurso, juego o encuesta).</p></li>
                                </ul>
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <ul>
                                <li><p>Creación y Gestión de Cuentas;</p></li>
                                <li><p>Formulario de suscripción a newsletters o boletines de noticias;</p></li>
                                <li><p>Registro o suscripción a juegos, promociones, ofertas, encuestas…</p></li>
                            </ul>
                            <br/></td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>Sus datos pueden ser cedidos a L’Oréal S.A., matriz del grupo de entidades afiliadas a
                                L’Oréal (“Grupo L'Oreal”), ubicada en Francia, sobre la base legal del interés legítimo
                                del Grupo L’Oreal, para cumplir con fines administrativos del Grupo L’Oréal.
                                <br/>
                                <br/>
                                <strong>Prestadores de servicios con acceso a sus datos</strong>:
                                <br/>
                                <br/>

                                <ul>
                                    <li><p>Terceros para entregarle un producto/muestra, por ejemplo, servicios postales
                                        / de entrega;</p></li>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios de IT, como proveedores de
                                        plataforma, servicios de alojamiento, mantenimiento y soporte en nuestras bases
                                        de datos, así como en nuestro software y aplicaciones que pueden contener datos
                                        sobre usted;</p></li>
                                    <li><p>Agencias de publicidad, marketing, medios digitales y redes sociales para
                                        ayudarnos a ofrecer publicidad, marketing y campañas, para analizar su
                                        efectividad y para administrar su contacto y sus preguntas;</p></li>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios digitales y de comercio
                                        electrónico como escucha social, localizador de tiendas, programas de lealtad,
                                        administración de identidades, calificaciones y reseñas, CRM, análisis web y
                                        motor de búsqueda, herramientas de curación de contenido generado por el
                                        usuario.</p></li>
                                </ul>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Gestión de la relación
                            contractual con usted que puede incluir:</strong>

                            - Informarle de la disponibilidad de un producto.

                            - Programa de fidelidad.

                            - Gestionar cualquier disputa relacionada con una compra;

                            - Medir su satisfacción.

                            - Administrar su participación en concursos, juegos y encuestas, incluso para tener en
                            cuenta sus comentarios y sugerencias

                            - Publicar su crítica o contenido.

                            - Brindarle el servicio solicitado (por ejemplo, probar virtualmente nuestros productos).

                            -Mostrarle la tienda más cercana a su ubicación.</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Cumplimiento de una relación
                            contractual</strong>: mantenimiento, cumplimiento, desarrollo, control y ejecución de la
                            relación contractual establecida entre usted y L’Oréal.</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Nombre y apellidos o alias;</p></li>
                                    <li><p>Género;</p></li>
                                    <li><p>Dirección de correo electrónico;</p></li>
                                    <li><p>Dirección postal (entrega y facturación);</p></li>
                                    <li><p>Número de teléfono;</p></li>
                                    <li><p>Foto;</p></li>
                                    <li><p>Fecha de nacimiento o rango de edad;</p></li>
                                    <li><p>ID,</p></li>
                                    <li><p>Ubicación;</p></li>
                                    <li><p>Geolocalización;</p></li>
                                    <li><p>Datos de bienestar, incluido el tono de la piel, el tipo de piel / cabello,
                                        etc;</p></li>
                                    <li><p>Descripción personal o preferencias;</p></li>
                                    <li><p>Perfil de redes sociales (cuando uses el inicio de sesión social o compartas
                                        esta información personal);</p></li>
                                    <li><p>Otra información que comparta con nosotros sobre usted (por ejemplo, fotos o
                                        reseñas, o preguntas a través de la función de chat disponible en algunos sitios
                                        web / aplicaciones o participando en un concurso, juego o encuesta).</p></li>
                                </ul>
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Creación y Gestión de Cuentas;</p></li>
                                    <li><p>Información recopilada durante el proceso de compra realizada en el sitio
                                        web/aplicaciones/ redes sociales de L'Oréal o en la tienda online;</p></li>
                                    <li><p>Registro o suscripción a juegos, promociones, ofertas, encuestas…</p></li>
                                    <li><p>A través del uso de aplicaciones o dispositivos de L’Oréal.</p></li>
                                    <li><p>Contenido generado y enviado por el usuario a alguna de nuestras plataformas
                                        sociales.</p></li>
                                </ul>
                                <br/></p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>Sus datos pueden ser cedidos a L’Oréal S.A., matriz del Grupo L’Oréal, ubicada en
                                Francia, sobre la base legal del interés legítimo del Grupo, para cumplir con fines
                                administrativos del Grupo.

                                <strong>Prestadores de servicios con acceso a sus datos:</strong>
                                <ul>
                                    <li><p>Terceros para entregarle un producto/muestra, por ejemplo, servicios postales
                                        / de entrega;</p></li>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios de IT, como proveedores de
                                        plataforma, servicios de alojamiento, mantenimiento y soporte en nuestras bases
                                        de datos, así como en nuestro software y aplicaciones que pueden contener datos
                                        sobre usted;</p></li>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios digitales y de comercio
                                        electrónico como escucha social, localizador de tiendas, programas de lealtad,
                                        administración de identidades, calificaciones y reseñas, CRM, análisis web y
                                        motor de búsqueda, herramientas de curación de contenido generado por el
                                        usuario.</p></li>
                                </ul>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Elaboración de perfiles que
                            incluye:</strong>

                            - Mejorar nuestros sitios web / aplicaciones.

                            -Enriquecer su perfil si hace una compra utilizando la información de su cuenta.

                            - Para la monitorización y la mejora de nuestras aplicaciones y dispositivos.

                            - Analizar sus características de bienestar y recomendarle los productos apropiados
                            (incluidos productos a medida) y las rutinas a seguir.

                            - Proporcionarle recomendaciones de productos y de rutinas.

                            <br/></p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Interés legítimo: </strong>Para
                            garantizar que nuestros sitios web/aplicaciones permanecen seguros, para ayudarnos a
                            comprender mejor sus necesidades y expectativas y, por lo tanto, mejorar nuestros servicios,
                            productos y marcas.</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Nombre y apellidos;</p></li>
                                    <li><p>Género;</p></li>
                                    <li><p>Dirección de correo electrónico;</p></li>
                                    <li><p>Dirección postal (entrega y facturación);</p></li>
                                    <li><p>Número de teléfono;</p></li>
                                    <li><p>Fecha de nacimiento o rango de edad;</p></li>
                                    <li><p>Foto;</p></li>
                                    <li><p>ID, nombre de usuario y contraseña;</p></li>
                                    <li><p>Datos de bienestar, incluido el tono de la piel, el tipo de piel / cabello,
                                        etc;</p></li>
                                    <li><p>Descripción personal o preferencias;</p></li>
                                    <li><p>Perfil de redes sociales (donde usa el inicio de sesión social o comparte
                                        esta información personal con nosotros);</p></li>
                                </ul>
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Creación y Gestión de Cuentas;</p></li>
                                    <li><p>Información recopilada durante el proceso de compra realizada en el sitio
                                        web/aplicaciones/ redes sociales de L'Oréal o en la tienda online;</p></li>
                                    <li><p>A través del uso de aplicaciones o dispositivos de L’Oréal.</p></li>
                                </ul>
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>Sus datos pueden ser cedidos a L’Oréal S.A., matriz del Grupo L’Oréal, ubicada en
                                Francia, sobre la base legal del interés legítimo del Grupo, para cumplir con fines
                                administrativos del Grupo.

                                <strong>Prestadores de servicios con acceso a sus datos:</strong>
                                <ul>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios de IT, como proveedores de
                                        plataforma, servicios de alojamiento, mantenimiento y soporte en nuestras bases
                                        de datos, así como en nuestro software y aplicaciones que pueden contener datos
                                        sobre usted.</p></li>
                                    <li><p>Agencias de publicidad, marketing, medios digitales y redes sociales para
                                        ayudarnos a ofrecer publicidad, marketing y campañas, para analizar su
                                        efectividad y para administrar su contacto y sus preguntas;</p></li>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios digitales y de comercio
                                        electrónico como escucha social, localizador de tiendas, programas de lealtad,
                                        administración de identidades, calificaciones y reseñas, CRM, análisis web y
                                        motor de búsqueda, herramientas de curación de contenido generado por el
                                        usuario.</p></li>
                                </ul>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Mantenimiento de listas de
                            supresión actualizadas para evitar ser contactado si usted lo solicita.</strong></p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Obligación
                            legal: </strong>El <em>General Data Protection Regulation</em> nos habilita a mantener sus
                            detalles en una lista de supresión, si nos ha pedido que no le enviemos más comunicaciones
                            comerciales.</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Nombre y apellidos;</p></li>
                                    <li><p>Dirección de correo electrónico;</p></li>
                                    <li><p>Descripción personal o preferencias;</p></li>
                                    <li><p>Perfil de redes sociales (cuando uses el inicio de sesión social o compartas
                                        esta información personal con nosotros).</p></li>
                                </ul>
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Suscripción a newsletters y comunicaciones comerciales.</p></li>
                                </ul>
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>Sus datos pueden ser cedidos a L’Oréal S.A., matriz del Grupo L’Oréal, ubicada en
                                Francia, sobre la base legal de la obligación legal de mantener sus detalles en una
                                lista de supresión, si nos ha pedido que no le enviemos más comunicaciones comerciales.

                                <strong>Prestadores de servicios con acceso a sus datos:</strong>
                                <ul>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios de TI, como proveedores de
                                        plataforma, servicios de alojamiento, mantenimiento y soporte en nuestras bases
                                        de datos, así como en nuestro software y aplicaciones que pueden contener datos
                                        sobre usted.</p></li>
                                    <li><p>Agencias de publicidad, marketing, medios digitales y redes sociales para
                                        ayudarnos a ofrecer publicidad, marketing y campañas, para analizar su
                                        efectividad y para administrar su contacto y sus preguntas;</p></li>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios digitales y de comercio
                                        electrónico como escucha social, localizador de tiendas, programas de lealtad,
                                        administración de identidades, calificaciones y reseñas, CRM, análisis web y
                                        motor de búsqueda, herramientas de curación de contenido generado por el
                                        usuario.</p></li>
                                </ul>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Permitir el funcionamiento de
                            nuestro sitio web / aplicaciones a través de cookies técnicas y funcionales que
                            incluye:</strong>

                            - Visualización adecuada del contenido.

                            - Creación y recuerdo de su inicio de sesión.

                            - Personalización de la interfaz, como el idioma;

                            - Parámetros adjuntos a su dispositivo, incluida la resolución de su pantalla, etc.

                            -Mejora de nuestros sitios web / aplicaciones.

                            - Asegurar que el sitio web o la aplicación sean seguros y se encuentren protegidos.

                            - Para evitar que los visitantes se guarden dos veces.

                            - Permitir compartir nuestro contenido en las redes sociales (compartir botones para mostrar
                            el sitio).</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Interés legítimo: </strong>Para
                            garantizar que le proporcionamos sitios web / aplicaciones, que funcionen correctamente y
                            que mejoren continuamente las cookies que (i) son esenciales para el funcionamiento de
                            nuestros sitios web / aplicaciones, (ii) se utilizan para mantener nuestros sitios web /
                            aplicaciones seguros y seguro</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Datos relacionados con el uso de las aplicaciones o webs;</p></li>
                                    <li><p>Datos relacionados con la página web de la que vienes;</p></li>
                                    <li><p>Detalles de registro;</p></li>
                                    <li><p>Páginas que visitaste;</p></li>
                                    <li><p>Videos que viste;</p></li>
                                    <li><p>Anuncios en los que haces click;</p></li>
                                    <li><p>Productos que buscas;</p></li>
                                    <li><p>Ubicación;</p></li>
                                    <li><p>Duración de tu visita;</p></li>
                                    <li><p>Información técnica:</p></li>
                                    <li><p>Dirección IP;</p></li>
                                    <li><p>Información del navegador;</p></li>
                                    <li><p>Información del dispositivo;</p></li>
                                    <li><p>Un identificador único otorgado a cada visitante y la fecha de vencimiento de
                                        dicho identificador.</p></li>
                                </ul>
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Información recopilada por cookies o tecnologías similares como parte de su
                                        navegación en el sitio web / aplicaciones de L'Oréal.</p></li>
                                </ul>
                                Para obtener información sobre Cookies específicas colocadas alojadas en un sitio web o
                                aplicación determinado, consulte nuestra Política de Cookies
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p><strong>Prestadores de servicios con acceso a sus datos:</strong>
                                <ul>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios de IT, como proveedores de
                                        plataforma, servicios de alojamiento, mantenimiento y soporte en nuestras bases
                                        de datos, así como en nuestro software y aplicaciones que pueden contener datos
                                        sobre usted.</p></li>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios digitales y de comercio
                                        electrónico como escucha social, localizador de tiendas, programas de lealtad,
                                        administración de identidades, calificaciones y reseñas, CRM, análisis web y
                                        motor de búsqueda, herramientas de curación de contenido generado por el
                                        usuario.</p></li>
                                </ul>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Inserción de publicidad basada
                            en el comportamiento que incluye:</strong>

                            - Mostrarle anuncios en línea de productos que pueden ser de su interés, en función de su
                            comportamiento anterior;

                            - Mostrarle anuncios y contenido en las plataformas de redes sociales;

                            - Enviarle recomendaciones, marketing o contenido en función de su perfil e intereses;</p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Consentimiento</strong></p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Datos relacionados con el uso de las aplicaciones o webs;</p></li>
                                    <li><p>Datos relacionados con la página web de la que vienes;</p></li>
                                    <li><p>Detalles de registro;</p></li>
                                    <li><p>Páginas que visitaste;</p></li>
                                    <li><p>Videos que viste;</p></li>
                                    <li><p>Anuncios en los que haces click;</p></li>
                                    <li><p>Productos que buscas;</p></li>
                                    <li><p>Ubicación;</p></li>
                                    <li><p>Duración de tu visita;</p></li>
                                    <li><p>Información técnica:</p></li>
                                    <li><p>Dirección IP;</p></li>
                                    <li><p>Información del navegador;</p></li>
                                    <li><p>Información del dispositivo;</p></li>
                                    <li><p>Un identificador único otorgado a cada visitante y la fecha de vencimiento de
                                        dicho identificador.</p></li>
                                </ul>
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Información recopilada por cookies o tecnologías similares como parte de su
                                        navegación en el sitio web / aplicaciones de L'Oréal.</p></li>
                                </ul>
                                Para obtener información sobre Cookies específicas colocadas alojadas en un sitio web o
                                aplicación determinado, consulte nuestra Política de Cookies
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>Cuando utilizamos los servicios publicitarios de Google en nuestros sitios web o
                                aplicaciones, sus datos podrán ser cedidos a Google para el tratamiento de los mismos
                                por parte de éste. Si desea obtener más información sobre el uso que hace Google de sus
                                datos personales en este contexto, consulte la Política de privacidad de Google que
                                cubre estos servicios publicitarios y el tratamiento de datos correspondiente.

                                Prestadores de servicios con acceso a sus datos:
                                <ul>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios de TI, como proveedores de
                                        plataforma, servicios de alojamiento, mantenimiento y soporte en nuestras bases
                                        de datos, así como en nuestro software y aplicaciones que pueden contener datos
                                        sobre usted.</p></li>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios digitales y de comercio
                                        electrónico como escucha social, localizador de tiendas, programas de lealtad,
                                        administración de identidades, calificaciones y reseñas, CRM, análisis web y
                                        motor de búsqueda, herramientas de curación de contenido generado por el
                                        usuario.</p></li>
                                </ul>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Servicio de atención al
                            consumidor que incluye:</strong>

                            - Responder a la preguntas que pueda hacernos a través de nuestros sitios web /
                            aplicaciones.

                            <br/>

                            <br/></p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Interés legítimo: </strong>Para
                            responder a sus solicitudes y preguntas respecto de nuestros productos y servicios.</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Nombre y apellidos;</p></li>
                                    <li><p>Número de teléfono;</p></li>
                                    <li><p>Dirección de correo electrónico;</p></li>
                                    <li><p>Otra información que comparta con nosotros sobre usted en relación con su
                                        consulta.</p></li>
                                </ul>
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Información recopilada cuando se le pregunta a través de encuestas sobre
                                        cuestiones relativas a marcas, productos, y su uso.</p></li>
                                    <li><p>Información recopilada cuando se pone en contacto con nosotros para realizar
                                        preguntas.</p></li>
                                </ul>
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p><strong>Prestadores de servicios con acceso a sus datos:</strong>
                                <ul>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios de TI, como proveedores de
                                        plataforma, servicios de alojamiento, mantenimiento y soporte en nuestras bases
                                        de datos, así como en nuestro software y aplicaciones que pueden contener datos
                                        sobre usted;</p></li>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios digitales y de comercio
                                        electrónico como escucha social, localizador de tiendas, programas de lealtad,
                                        administración de identidades, calificaciones y reseñas, CRM, análisis web y
                                        motor de búsqueda, herramientas de curación de contenido generado por el
                                        usuario.</p></li>
                                </ul>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Realización de estadísticas
                            que incluye:</strong>

                            - Realización de informes y estadísticas

                            - Conocer la reacción de los usuarios a nuestras campañas publicitarias;

                            - Para mejorar nuestras ofertas;

                            - Para saber cómo descubrió nuestros sitios web/aplicaciones.</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>Interés legítimo: </strong>Para
                            ayudarnos a comprender mejor las necesidades y expectativas de nuestros clientes y, por lo
                            tanto, mejorar nuestros servicios, productos y marcas.</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Nombre y apellidos o alias;</p></li>
                                    <li><p>Género;</p></li>
                                    <li><p>Dirección de correo electrónico;</p></li>
                                    <li><p>Dirección postal (entrega y facturación);</p></li>
                                    <li><p>Número de teléfono;</p></li>
                                    <li><p>Foto;</p></li>
                                    <li><p>Fecha de nacimiento o rango de edad;</p></li>
                                    <li><p>ID,</p></li>
                                    <li><p>Ubicación;</p></li>
                                    <li><p>Geolocalización;</p></li>
                                    <li><p>Datos de bienestar, incluido el tono de la piel, el tipo de piel / cabello,
                                        etc;</p></li>
                                    <li><p>Descripción personal o preferencias;</p></li>
                                    <li><p>Perfil de redes sociales (cuando uses el inicio de sesión social o compartas
                                        esta información personal);</p></li>
                                    <li><p>Otra información que comparta con nosotros sobre usted (por ejemplo, fotos o
                                        reseñas, o preguntas a través de la función de chat disponible en algunos sitios
                                        web / aplicaciones o participando en un concurso, juego o encuesta);</p></li>
                                    <li><p>Datos relacionados con el uso de las aplicaciones o webs;</p></li>
                                    <li><p>Datos relacionados con la página web de la que vienes;</p></li>
                                    <li><p>Detalles de registro;</p></li>
                                    <li><p>Páginas que visitaste;</p></li>
                                    <li><p>Videos que viste;</p></li>
                                    <li><p>Anuncios en los que haces click;</p></li>
                                    <li><p>Productos que buscas;</p></li>
                                    <li><p>Ubicación;</p></li>
                                    <li><p>Duración de tu visita;</p></li>
                                    <li><p>Información técnica:</p></li>
                                    <li><p>Dirección IP;</p></li>
                                    <li><p>Información del navegador;</p></li>
                                    <li><p>Información del dispositivo;</p></li>
                                    <li><p>Un identificador único otorgado a cada visitante y la fecha de vencimiento de
                                        dicho identificador.</p></li>
                                </ul>
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>
                                <ul>
                                    <li><p>Información recopilada cuando se le pregunta a través de encuestas sobre
                                        cuestiones relativas a marcas, productos, y su uso.</p></li>
                                    <li><p>A través del uso de aplicaciones o dispositivos de L’Oréal.</p></li>
                                    <li><p>Contenido generado y enviado por el usuario a alguna de nuestras plataformas
                                        sociales.</p></li>
                                    <li><p>Registro o suscripción a juegos, promociones, ofertas, encuestas…</p></li>
                                    <li><p>Suscripción a newsletters y comunicaciones comerciales;</p></li>
                                    <li><p>Creación y Gestión de Cuentas;</p></li>
                                    <li><p>Información recopilada por cookies o tecnologías similares como parte de su
                                        navegación en el sitio web / aplicaciones de L'Oréal.</p></li>
                                </ul>
                                Para obtener información sobre Cookies específicas colocadas alojadas en un sitio web o
                                aplicación determinado, consulte nuestra Política de Cookies
                            </p>
                        </td>
                        <td className="p-2 border-solid border-2 border-black align-top">
                            <p>Sus datos pueden ser cedidos a L’Oréal S.A., matriz del Grupo L’Oréal, ubicada en
                                Francia, sobre la base legal del interés legítimo del Grupo, para cumplir con fines
                                administrativos del Grupo.

                                <strong>Prestadores de servicios con acceso a sus datos:</strong>
                                <ul>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios de TI, como proveedores de
                                        plataforma, servicios de alojamiento, mantenimiento y soporte en nuestras bases
                                        de datos, así como en nuestro software y aplicaciones que pueden contener datos
                                        sobre usted;</p></li>
                                    <li><p>Terceros que nos ayudan a proporcionar servicios digitales y de comercio
                                        electrónico como escucha social, localizador de tiendas, programas de lealtad,
                                        administración de identidades, calificaciones y reseñas, CRM, análisis web y
                                        motor de búsqueda, herramientas de curación de contenido generado por el
                                        usuario.</p></li>
                                </ul>
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/><br/>
                <strong>PERFILADO</strong>
                <br/><br/>
                Cuando enviamos o mostramos comunicaciones o contenidos personalizados, podemos usar algunas técnicas
                calificadas como "perfilado" (es decir, cualquier forma de tratamiento automatizado de datos personales
                que consiste en utilizar esos datos para evaluar ciertos aspectos personales de una persona física, en
                particular para analizar o predecir aspectos relacionados con las preferencias personales, intereses,
                situación económica, comportamiento, ubicación, salud, fiabilidad o movimientos de esa persona física).
                Esto significa que podemos recabar datos personales sobre usted para las para llevar a cabo este
                perfilado, tal y como se recoge en la tabla anterior. Centralizamos esta información y la analizamos
                para evaluar y predecir sus preferencias y / o intereses personales.

                En función de nuestro análisis, enviamos o mostramos comunicaciones y / o contenidos adaptados a sus
                intereses / necesidades.

                Tiene derecho a oponerse a que sus datos personales sean objeto de un tratamiento basado en la
                elaboración de perfiles en ciertas circunstancias. Consulte la sección "Sus derechos y opciones" más
                abajo.
                <br/><br/>
                <strong>¿QUIÉN PUEDE ACCEDER A SUS DATOS PERSONALES?</strong>
                <br/><br/>
                <strong>Podemos compartir sus datos personales dentro del Grupo L'Oréal. </strong>Tal y como se menciona
                en la tabla anterior en la columna “<em>¿Quién/Quiénes pueden tener acceso a sus Datos Personales?</em>”,
                sus datos podrán ser cedidos a L’Oréal S.A., matriz del Grupo L’Oréal, ubicada en Francia, sobre la base
                legal del interés legítimo del Grupo, para cumplir con fines administrativos del Grupo, sobre la base
                legal de la obligación legal de mantener sus detalles en una lista de supresión, si nos ha pedido que no
                le enviemos más comunicaciones comerciales.
                <br/>
                <strong>Sus datos personales también pueden ser tratados </strong><strong>por </strong><strong>solicitud
                nuestra, por terceros que fungen como nuestros proveedores de confianza. </strong>Suscribimos contratos
                con terceros de confianza para que realicen una variedad de operaciones comerciales en nuestro nombre.
                Sólo les proporcionamos la información que necesitan para realizar el servicio, y les exigimos que no
                utilicen sus datos personales para ningún otro propósito. Siempre hacemos nuestro mayor esfuerzo para
                garantizar que todos los terceros con los que trabajamos mantengan la seguridad de sus datos personales.
                <br/>
                Por favor acuda a la tabla anterior, en la columna “<em>¿Quién/Quiénes pueden tener acceso a sus Datos
                Personales?</em>” para conocer las categorías de terceros prestadores de servicios que requieren el
                acceso a sus datos personales. No ofrecemos ni vendemos sus datos personales.
                <br/><br/>
                <strong>¿CUÁNTO TIEMPO CONSERVAMOS SUS DATOS PERSONALES?</strong>
                <br/><br/>
                Sólo conservamos sus Datos Personales durante el tiempo que los necesitemos para el propósito para el
                que tratamos sus Datos Personales, para satisfacer sus necesidades o para cumplir con nuestras
                obligaciones legales.

                Para determinar el período de retención de datos de sus Datos Personales, utilizamos los siguientes
                criterios:
                <ul>
                    <li><p>Datos Personales obtenidos al participar de nuestros servicios, mientras dure nuestra
                        relación contractual;</p></li>
                    <li><p>Datos Personales obtenidos al participar en una oferta promocional: durante la vigencia de la
                        oferta promocional;</p></li>
                    <li><p>Datos Personales obtenidos al contactar con nosotros para una consulta: Datos Personales
                        durante el tiempo necesario para atender su consulta;</p></li>
                    <li><p>Datos Personales obtenidos al crear una cuenta: hasta que nos pida que los eliminemos o
                        después de un período de inactividad (sin interacción activa con las marcas) de tres (3)
                        años;</p></li>
                    <li><p>Datos Personales obtenidos al prestar su consentimiento para el envío de comunicaciones
                        comerciales: hasta que cancele la suscripción, exija que lo eliminemos o después de un período
                        de inactividad (sin interacción activa con las marcas) de tres años;</p></li>
                    <li><p>Cookies que se instalan en su ordenador: las guardamos durante el tiempo necesario para
                        lograr sus propósitos (por ejemplo, durante una sesión para cookies de identificación de sesión)
                        y durante un máximo de 365 días.</p></li>
                </ul>
                Es posible que retengamos algunos datos personales para cumplir con nuestras obligaciones legales o
                reglamentarias, así como para administrar nuestros derechos (por ejemplo, para hacer valer nuestras
                reclamaciones ante los tribunales) o con fines estadísticos o históricos.

                Cuando ya no necesitemos usar sus datos personales, se eliminarán de nuestros sistemas y registros o se
                anonimizarán para que ya no podamos identificarlos.
                <br/><br/>
                <strong>¿MIS DATOS PERSONALES SE GUARDAN DE FORMA SEGURA?</strong>
                <br/><br/>
                Nos comprometemos a proteger sus Datos Personales y a tomar todas las precauciones razonables para
                hacerlo. Exigimos contractualmente que los terceros de confianza que manejan sus Datos Personales hagan
                lo mismo.

                Siempre hacemos nuestro mejor esfuerzo para proteger sus Datos Personales y una vez que hemos recibido
                su información personal, utilizamos procedimientos estrictos y funciones de seguridad para tratar de
                evitar el acceso no autorizado. Como la transmisión de información a través de Internet no es
                completamente segura, no podemos garantizar la seguridad de sus datos transmitidos a nuestro sitio. Por
                ello, cualquier transmisión es bajo su propio riesgo.
                <br/><br/>
                <strong>ENLACES A SITIOS DE TERCEROS Y SOCIAL LOGIN</strong>
                <br/><br/>
                Nuestros sitios web y aplicaciones pueden contener enlaces hacia y desde los sitios web de nuestras
                redes asociadas, anunciantes y afiliados. Si sigue un enlace a cualquiera de estos sitios web, tenga en
                cuenta que estos sitios web tienen sus propias políticas de privacidad y que no somos responsables de
                estas políticas. Consulte estas políticas antes de enviar datos personales a estos sitios web.
                <br/>
                También podemos ofrecerle la oportunidad de utilizar su inicio de sesión en las redes sociales para
                registrarse en L’Oréal. Si lo hace, tenga en cuenta que comparte su información de perfil social con
                nosotros. Los datos personales compartidos dependen de la configuración de su plataforma de medios
                sociales. Visite la plataforma de redes sociales en cuestión y revise su política de privacidad para
                comprender cómo se comparten y usan sus datos personales en este contexto.
                <br/><br/>
                <strong>SUS DERECHOS Y OPCIONES</strong>
                <br/><br/>
                L’Oreal respeta su derecho a la privacidad: es importante que usted tenga el control sobre sus datos de
                carácter personal. En este sentido le corresponden los siguientes derechos:
                <table>
                    <tbody>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black"><p><strong>Sus derechos</strong></p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p><strong>¿Qué significa?</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black"><p>Derecho de información</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p>

                            Tiene derecho a obtener información clara, transparente y fácil de entender sobre la forma
                            en que usamos sus datos personales y sobre sus derechos. Le facilitamos dicha información en
                            esta Política.</p></td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black"><p>Derecho de acceso</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p>

                            Tiene derecho a acceder a los datos personales que tenemos de usted (con ciertos límites).

                            Podremos cobrar una cantidad razonable para cubrir los costes administrativos incurridos al
                            facilitar la información.

                            Las solicitudes manifiestamente infundadas, excesivas o repetitivas podrán no ser atendidas.

                            Para ejercer este derecho, póngase en contacto con nosotros por cualquiera de los medios
                            abajo indicados.</p></td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black"><p>Derecho de rectificación</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p>
                            Tiene derecho a hacer que se rectifiquen sus datos personales cuando sean inexactos o hayan
                            dejado de ser válidos o a hacer que se completen cuando sean incompletos.

                            Para ejercer este derecho, póngase en contacto con nosotros por cualquiera de los medios
                            abajo indicados. Si tiene una cuenta, puede resultar más sencillo que los corrija usted
                            mismo mediante la modificación de su perfil.</p></td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black"><p>Derecho de supresión/derecho al
                            olvido</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p>

                            En determinados casos, le corresponde el derecho a hacer que sus datos personales sean
                            borrados o eliminados. Es preciso señalar que no se trata de un derecho absoluto, puesto que
                            podremos tener motivos legales o legítimos para conservarlos.

                            Si desea que suprimamos sus datos personales, póngase en contacto con nosotros por
                            cualquiera de los medios abajo indicados.</p></td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black"><p>Derecho de oposición al marketing
                            directo, incluida la elaboración de perfiles</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p>

                            Puede darse de baja de nuestras comunicaciones de marketing directo en cualquier momento.

                            La forma más fácil de darse de baja es haciendo clic sobre el enlace “Darse de baja” en
                            cualquier correo electrónico o comunicación que le enviemos. Si no, póngase en contacto con
                            nosotros por cualquiera de los medios abajo indicados.

                            Para oponerse a la elaboración de perfiles, póngase en contacto con nosotros por los medios
                            abajo indicados.</p></td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black"><p>
                            Derecho a retirar el consentimiento en cualquier momento cuando el tratamiento de datos esté
                            basado en el consentimiento</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p>

                            Puede retirar su consentimiento al tratamiento de sus datos personales cuando el tratamiento
                            esté basado en su consentimiento. La retirada del consentimiento no afectará a la licitud
                            del tratamiento basado en el consentimiento previo a su retirada. Para informarse de los
                            casos en que el tratamiento está basado en el consentimiento, le remitimos a la tabla
                            incluida en el apartado “¿Qué datos suyos recabamos?”, en particular la columna “¿Cuál es la
                            base legal para tratar sus Datos Personales?”.

                            Si desea retirar su consentimiento, póngase en contacto con nosotros por cualquiera de los
                            medios abajo indicados.</p></td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black"><p>Derecho de oposición al tratamiento
                            basado en la satisfacción de intereses legítimos</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p>

                            Puede oponerse en cualquier momento a que tratemos sus datos cuando el tratamiento esté
                            basado en la satisfacción de intereses legítimos. Para informarse de los casos en que el
                            tratamiento está basado en intereses legítimos, le remitimos a la tabla incluida en el
                            apartado “¿Qué datos suyos recabamos?”, en particular la columna “¿Cuál es la base legal
                            para tratar sus Datos Personales?”.

                            Si desea ejercer este derecho, póngase en contacto con nosotros por cualquiera de los medios
                            abajo indicados.</p></td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black"><p>

                            Derecho a presentar una reclamación ante una autoridad de control</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p>

                            No dude en ponerse en contacto con nosotros por cualquiera de los medios abajo indicados
                            antes de presentar una reclamación ante la autoridad competente en materia de protección de
                            datos.</p></td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black"><p>Derecho a la portabilidad de los
                            datos</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p>

                            Tiene derecho a trasladar, copiar o transferir datos desde nuestra base de datos a otra
                            distinta. Solo es posible ejercer este derecho con respecto a datos que haya facilitado,
                            cuando el tratamiento esté basado en la ejecución de un contrato o en su consentimiento y el
                            tratamiento se realice por medios automatizados. Para informarse de los casos en que el
                            tratamiento está basado en contrato o en consentimiento, le remitimos a las tabla incluida
                            en el apartado “¿Qué datos suyos recabamos?”, en particular la columna “¿Cuál es la base
                            legal para tratar sus Datos Personales?”.

                            Para más información, póngase en contacto con nosotros por cualquiera de los medios abajo
                            indicados.</p></td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black"><p>Derecho de limitación del
                            tratamiento</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p>

                            Tiene derecho a solicitar la limitación del tratamiento de sus datos. Si ejerce este
                            derecho, el tratamiento de sus datos estará sujeto a limitaciones, por lo que podremos
                            almacenarlos pero no podremos seguir usándolos ni tratándolos.

                            Este derecho solo puede ejercerse en determinadas circunstancias definidas por el Reglamento
                            General de Protección de Datos, como sigue:

                            que el interesado impugne la exactitud de los datos personales, durante el plazo que permita
                            al responsable verificar la exactitud de los mismos;

                            que el tratamiento sea ilícito y el interesado se oponga a la supresión de los datos
                            personales y solicite en su lugar la limitación de su uso;

                            que el responsable ya no necesite los datos personales para los fines del tratamiento, pero
                            el interesado los necesite para la formulación, el ejercicio o la defensa de reclamaciones;

                            que el interesado se haya opuesto al tratamiento en virtud del artículo 21, apartado 1,
                            mientras se verifica si los motivos legítimos del responsable prevalecen sobre los del
                            interesado.

                            Si desea ejercer este derecho, póngase en contacto con nosotros por cualquiera de los medios
                            abajo indicados.</p></td>
                    </tr>
                    <tr>
                        <td className="p-2 border-solid border-2 border-black"><p>Derecho a la desactivación de
                            Cookies</p></td>
                        <td className="p-2 border-solid border-2 border-black align-top"><p>
                            Tiene derecho a la desactivación de los Cookies. La configuración de los navegadores de
                            Internet suele estar programada por defecto para aceptar Cookies, pero pueden desactivarse
                            fácilmente cambiando la configuración del navegador.

                            Muchas cookies se utilizan para mejorar la usabilidad o funcionalidad de los sitios
                            web/aplicaciones; por lo tanto, su desactivación puede impedirle usar ciertas partes de
                            nuestras webs/aplicaciones tal y como se indica en la Política de Cookies.

                            Si desea limitar o bloquear todas las cookies establecidas por nuestras webs/aplicaciones
                            (lo que podrá impedirle usar ciertas partes de la web) o por cualesquiera otras
                            webs/aplicaciones, puede hacerlo a través de la configuración de su navegador.</p></td>
                    </tr>
                    </tbody>
                </table>
                Para tramitar su solicitud, podremos pedirle que acredite su identidad.
                <br/><br/>
                <strong>CONTACTO</strong>
                <br/><br/>
                Si tiene cualquier duda o preocupación acerca de la forma en que tratamos y usamos sus datos personales
                o desea ejercer cualquiera de los derechos anteriormente descritos, póngase en contacto con <a
                href="mailto:DMCA@loreal.com">DMCA@loreal.com</a> o escríbanos a la siguiente dirección T-Mobile Tower,
                San Patricio Village, Suite 1700 B7 Calle Tabonuco, Guaynabo PR 00968.
                <br/>
                <br/>
            </div>
            <Brands data={json.brands} pr={project === "pr"} />
            <Footer data={json.footer}/>
        </div>
    )
}


export default TermsAndConditions;

