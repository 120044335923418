import React from "react";
import {BrandsData} from "../../interfaces/BrandsData";

export function Brands({data, pr}: { data: BrandsData, pr: boolean }) {
    return (
        <div className="bg-gray-50 p-10">
            <div className="md:w-6/12 mx-auto">
                <img src={data.logo} alt="logo" className="md:w-1/3 w-2/3 mx-auto"/>
                <div className="md:w-1/5 w-2/5 my-8 mx-auto border-b border-1 border-black"/>
                <div className="text-center font-semibold mt-10 text-sm">{data.tittle}</div>
                <div className="text-center text-xs">{data.description}</div>
                {!pr &&
                    <div className="flex flex-col gap-4 my-6">
                        <div className="w-1/3 md:w-4/5 m-auto grid md:grid-flow-col auto-cols-auto gap-8">
                            {data.brands.slice(0, 4).map((item, index) => ( // First row with 4 brands
                                <a href={item.url} target="_blank">
                                    <img src={item.logo} alt="brand" key={index}/>
                                </a>
                            ))}
                        </div>
                        <div className="w-1/3 md:w-3/5 mx-auto mt-8 md:mt-auto grid md:grid-flow-col auto-cols-auto gap-8">
                            {data.brands.slice(4).map((item, index) => ( // First row with 4 brands
                                <a href={item.url} target="_blank">
                                    <img src={item.logo} alt="brand" key={index}/>
                                </a>
                            ))}
                        </div>
                    </div>
                }
                {pr &&
                    <div
                        className="w-1/3 md:w-4/5 m-auto grid md:grid-cols-4 grid-cols-1 gap-8 my-8 justify-items-center flex justify-between">
                        {data.brands.map((item, index) => (
                            <a href={item.url} target="_blank"> <img src={item.logo} alt="brand" key={index}/></a>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}