import React from "react";
import {TransformationData} from "../../interfaces/TransformationData";

export function Transformation({data}: { data: TransformationData }) {
    return (
        <div className="md:w-5/12 mx-auto my-8">
            <img src={data.logo} alt="logo" className="h-20 mx-auto my-8"/>
            <div className="text-base text-center mx-auto md:w-full w-11/12">{data.description}</div>
            <img src={data.image} alt="professionel" className="h-full my-6 mx-auto md:w-full w-11/12"/>
            <div className="w-fit mx-auto">
                <a target="_blank" href={data.url} className="bg-black text-white p-3 text-sm">Ir a Transformation</a>
            </div>

        </div>
    )
}