import React from 'react';
import {Header} from "./components/header/Header";
import {Banner} from "./components/banner/Banner";
import {Content} from "./components/content/Content";
import {Transformation} from "./components/transformation/Transformation";
import {Brands} from "./components/brands/Brands";
import {Footer} from "./components/footer/Footer";
import {GeneralData} from "./interfaces/GeneralData";
import pr from "./config/lorealprofessionalpr.com.json";
import rd from "./config/lorealppdrd.com.json";

const Home: React.FC = () => {
    const project = process.env.REACT_APP_PROJECT_NAME;
    const json: GeneralData | any = project === 'pr' ? pr : project === 'rd' ? rd : null;
    return (
        <div>
            <Header data={json.header} />
            <Banner data={json.banner} pr={project === "pr"} />
            <Content data={json.content} pr={project === "pr"} />
            {json.transformation && <Transformation data={json.transformation} />}
            <Brands data={json.brands} pr={project === "pr"} />
            <Footer data={json.footer} />
        </div>
    );
};

export default Home;