import React from "react";
import {FooterData} from "../../interfaces/FooterData";

export function Footer({data}:{data:FooterData}) {
    return (
        <div className="bg-black text-white px-20 py-4 justify-between text-center md:flex text-xs">
            <div className="hidden md:block">{data.text}</div>
            <div className="flex md:flex-row flex-col gap-4 my-4 md:my-0">
                <a href={data.termsUrl} className="underline">Términos y Condiciones</a>
                <a href={data.privacyUrl} className="underline mt-4 md:mt-0">Política de Privacidad</a>
            </div>
            <div className="block md:hidden">{data.text}</div>
        </div>
    )
}