import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import pr from './config/lorealprofessionalpr.com.json'
import rd from './config/lorealppdrd.com.json'
import {GeneralData} from "./interfaces/GeneralData";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const project = process.env.REACT_APP_PROJECT_NAME;
const json: GeneralData | any = project === 'pr' ? pr : project === 'rd' ? rd : null;
if (json === null) {
    throw new Error('Project not found');
}
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/home" element={<Home/>} />
              <Route path={"/home/terms-and-conditions"} element={<TermsAndConditions/>}/>
              <Route path={"/terms-and-conditions"} element={<TermsAndConditions/>}/>
              <Route path={"/privacy-policy"} element={<PrivacyPolicy/>}/>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
