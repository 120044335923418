import React, {useEffect, useState} from "react";
import {HeaderData} from "../../interfaces/HeaderData";

export function Header({data}: { data: HeaderData }) {

    const [selectedLink, setSelectedLink] = useState<string | null>(null)
    const [mobileMenu, setMobileMenu] = useState<boolean>(false)

    useEffect(() => {
        console.log(selectedLink)
    }, [selectedLink])
    return (
        <>
            {/*mobile*/}
            <div className="block md:hidden grid grid-cols-3 h-16">
                <div></div>
                <a href="/" className="w-full h-full mx-auto mt-4">
                    <img src={data.logo} alt="logo"/>
                </a>
                <div className="w-full h-full flex justify-end mt-3 pr-2">
                    <img src="./mobileNavigator.png" alt="menu" className="w-1/3 h-fit"
                         onClick={e => {
                             setMobileMenu(!mobileMenu)
                             setSelectedLink(null)
                         }}/>
                </div>
                {mobileMenu ?
                    <div className="bg-white h-screen w-screen p-8 z-50">
                        {data.brands.map((item, index) => (
                            <div key={index} className="my-2">
                                <div className="cursor-pointer flex items-center"
                                     onClick={e => selectedLink === item.name ?
                                         setSelectedLink(null) : setSelectedLink(item.name)
                                     }>{item.name}
                                    <img src="./dropdown.png" alt="dropdown" className="h-fit mx-2"/>
                                </div>
                                {selectedLink === item.name && (
                                    item.links.map((link, index) => (
                                        <div key={index} className="ml-8 my-2">
                                            <a href={link.url} target="_blank">{link.name}</a>
                                        </div>
                                    ))
                                )}
                            </div>
                        ))}
                    </div>
                    : null
                }
            </div>
            {/*desktop*/}
            <div className="md:block hidden bg-white h-32 p-4">
                <a href="/">
                    <img src={data.logo} className="w-1/8 h-2/3 m-auto" alt="logo"/>
                </a>
                <div className="flex gap-2 w-fit mx-auto mt-4">
                    {data.brands.map((item, index) => (
                        <a key={index} href={item.link}>
                            <div className="cursor-pointer relative flex items-center gap-2 mx-2"
                                 onMouseEnter={e => selectedLink === item.name ?
                                     setSelectedLink(null) : setSelectedLink(item.name)
                                 }>
                                {item.name}<img src="./dropdown.png" alt="dropdown" className="h-fit"/>
                            </div>
                            {selectedLink === item.name && (
                                <div className="gap-2 flex flex-col absolute z-50 top-32 bg-white p-2">
                                    {item.links.map((link, index) => (
                                        <a key={index} href={link.url} target="_blank">{link.name}</a>
                                    ))}
                                </div>
                            )}
                        </a>

                    ))}
                </div>
            </div>
        </>

    );
}
