import React from "react";
import {ContentData} from "../../interfaces/ContentData";

export function Content({data, pr}: { data: ContentData, pr: boolean }) {
    return (
        <div className="bg-gray-50 text-center p-10">
            <div className="text-4xl font-Playfair">{data.title}</div>
            <div className="text-xl md:w-2/3 mx-auto my-4">{data.description}
                <div className="md:w-1/3 mt-2 mx-auto border-b border-2 border-gray-400"/>
            </div>
            <div className="hidden md:block text-sm md:w-2/3 my-8 mx-auto">{data.text}</div>
            <div className="md:w-2/3 my-8 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
                {data.images.map((item, index) => (
                    <a href={item.url} target="_blank">
                        <div className="h-96 rounded-md" key={index}
                             style={{
                                 backgroundImage: `url(${item.image})`,
                                 backgroundSize: "cover",
                                 backgroundPosition: "top",
                                 backgroundRepeat: "none"
                             }}
                        >
                            <div
                                className={`pt-72 w-full h-full ${!pr && "bg-gradient-to-t from-black opacity-75 mix-blend-mode-multiply"}`}>
                                <img src={item.logo} alt="logo" className="w-1/2 bottom-4 mx-auto"/>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
            <div className="block md:hidden text-sm md:w-2/3 my-8 mx-auto">{data.text}</div>
        </div>
    )
}