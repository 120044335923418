import React from "react";
import {Header} from "./components/header/Header";
import {GeneralData} from "./interfaces/GeneralData";
import pr from "./config/lorealprofessionalpr.com.json";
import rd from "./config/lorealppdrd.com.json";
import {Brands} from "./components/brands/Brands";
import {Footer} from "./components/footer/Footer";

const TermsAndConditions: React.FC = () => {
    const project = process.env.REACT_APP_PROJECT_NAME;
    const json: GeneralData | any = project === 'pr' ? pr : project === 'rd' ? rd : null;
    return (
        <div>
            <Header data={json.header}/>
            <div className="w-2/3 m-auto text-sm">
                <h5 className="font-bold my-4 text-2xl">Términos de Uso Generales</h5><p>

                Salvo que se notifique lo contrario, los productos y servicios del Sitio son solo para uso personal, sin
                fines comerciales. Usted acepta usar el Sitio solamente con fines lícitos, no comerciales y en
                cumplimiento de todas las leyes internacionales, federales, estatales y locales. Excepto lo expresamente
                permitido por estos Términos de Uso, usted no puede usar, reproducir, distribuir, aplicar ingeniería
                inversa, modificar, copiar, publicar, mostrar, transmitir, adaptar, enmarcar, vincular, alquilar,
                usufructuar, prestar, vender, conceder licencias ni explotar de ningún otro modo el contenido del Sitio.

            </p><h5 className="font-bold my-4">Elegibilidad</h5><p>

                Usted declara y garantiza (a) ser mayor de edad de 16 años de edad; (b) no haber sido suspendido ni
                eliminado del Sitio anteriormente; (c) no tener más de una (1) cuenta del Sitio en cualquier momento
                determinado; (d) proporcionarnos solamente información verdadera, precisa, actual y completa si se
                registra para tener una cuenta y/o hacer Pedidos (según se define más abajo). Ante la creencia o
                sospecha de que la información que haya proporcionado no sea verdadera, precisa, actual o completa, o
                sospecha del uso fraudulento del sitio a través de herramientas automáticas u otros métodos, podremos
                denegar o suspender su acceso al Sitio o los Servicios (o a cualquier parte de estos); y (e) tener total
                facultad y derecho para suscribir estos Términos de Uso y, al hacerlo, no infringir ningún otro acuerdo
                del que sea parte.

            </p><h5 className="font-bold my-4">Contenido de Usuario</h5><p>

                Agradecemos los comentarios, la información y los envíos de los usuarios, si alguno, que puedan hacerse
                a través del Sitio. Además, usted y otros usuarios del Sitio ocasionalmente podrán tener la oportunidad
                de publicar en el Sitio ciertas ideas, conceptos, información, datos, textos, música, sonidos,
                fotografías, videos, mensajes, comentarios sobre nuestros productos, publicidad y otros eventos y
                materiales promocionales, hechos, asesoramiento, «consejos», opiniones y demás material (en
                conjunto,</p><h5 className="font-bold my-4">«Contenido de Usuario»)</h5><p>. Sujeto a nuestra Política
                de Privacidad, todo el Contenido de Usuario que se publique en el Sitio no será considerado confidencial
                ni de propiedad del usuario y podrá ser visualizado por usted y/u otros usuarios del Sitio. Usted
                también acepta que nosotros, nuestros afiliados y nuestros licenciatarios tenemos la libertad de usar
                las ideas, los conceptos, las técnicas o los conocimientos técnicos incluidos en cualquier Contenido de
                Usuario que nos envíe, cualquiera sea su fin, incluidos, entre otros, el desarrollo, la fabricación y la
                comercialización de productos, servicios y contenidos utilizando dicha información sin ningún crédito,
                notificación, aprobación o compensación a su persona.

                No podemos garantizar que otros usuarios no copien, modifiquen, distribuyan o utilicen de otro modo el
                Contenido de Usuario que usted comparta. Por lo tanto, si tiene una idea o información que desea
                mantener con carácter confidencial y/o no desea que otros utilicen, no la publique en el Sitio. Nosotros
                y nuestros afiliados no somos responsables del uso o la apropiación indebidos por parte de otros
                usuarios del Contenido de Usuario que publique en el Sitio.

            </p><h5 className="font-bold my-4">Revisión del Material Enviado</h5><p>

                No tenemos la obligación de verificar la identidad de los usuarios cuando se conectan al Sitio ni de
                supervisar el Contenido de Usuario que estos proporcionan, si alguno. Usted acepta que podemos o no
                preseleccionar, monitorear, revisar, editar o eliminar el Contenido de Usuario que usted u otros
                usuarios publican en el Sitio. Nosotros y nuestros delegados conservamos el derecho de modificar, mover,
                rechazar, bloquear o eliminar cualquier Contenido de Usuario, total o parcialmente, por cualquier motivo
                o sin motivo alguno, con o sin aviso. Nosotros y nuestros delegados también nos reservamos el derecho de
                acceder, leer, preservar y divulgar cualquier información ante la creencia razonable de que sea
                necesario para (i) cumplir con toda legislación, regulación, proceso legal o requisito gubernamental en
                vigencia; (ii) ejecutar estos Términos de Uso, incluidas las investigaciones de potenciales infracciones
                de estas; (iii) detectar, prevenir o abordar de otro modo problemas técnicos, de seguridad o fraude;
                (iv) responder a las solicitudes de asistencia de los usuarios; o (v) proteger nuestros derechos,
                propiedad o seguridad, a los usuarios del Sitio y al público en general.

            </p><h5 className="font-bold my-4">Conducta de los Usuarios</h5><p>

                Al publicar Contenido de Usuario o utilizar ocasionalmente de otro modo cualquiera de los servicios de
                comunicaciones o servicios interactivos disponibles en o a través del Sitio, si alguno, usted acepta ser
                la única persona y/o entidad responsable del Contenido de Usuario que origine. Además, acepta no acceder
                ni usar el Sitio para fines prohibidos por estos Términos de Uso. Usted es responsable de toda la
                actividad que desarrolle en conexión con el Sitio.

                A modo de ejemplo, entre otras cosas, acepta no usar el Sitio (ni permitir que terceros lo hagan) para:
                (a) cargar, publicar, enviar por correo electrónico o transmitir Contenido de Usuario que sea impreciso,
                ilícito, perjudicial, amenazante, vergonzante, abusivo, hostigante, tortuoso, difamatorio, vulgar,
                obsceno, ofensivo, profano, calumnioso, engañoso, fraudulento, invasivo de la privacidad de terceros,
                detestable o que contenga o represente desnudez o descripciones explícitas o gráficas o que represente
                actos sexuales o violentos (incluidos, entre otros, lenguaje sexual de naturaleza violenta o amenazante
                dirigido a otro individuo o grupo de individuos), o que sea inadecuado según lo determinado a nuestra
                exclusiva discreción; (b) perjudicar a cualquier otro de cualquier modo; (c) cargar, publicar, enviar
                por correo electrónico o transmitir Contenido de Usuario que (i) victimice, hostigue, degrade o intimide
                a otro individuo o grupo de individuos sobre la base de una clasificación inadmisible, incluidos, entre
                otros, religión, género, orientación sexual, raza, color, credo, etnicidad, nacionalidad, ciudadanía,
                edad, estado civil, condición de veterano o discapacidad; (ii) infrinja patentes, marcas comerciales,
                secretos comerciales, propiedad intelectual, derecho de publicidad o derecho de propiedad intelectual de
                terceros; y (iii) usted no tenga derecho de transmitir de conformidad con la ley o con una relación
                contractual o fiduciaria (como información privilegiada, intelectual, patentada o confidencial de la
                cual tenga conocimiento o le haya sido divulgada como parte de su relación laboral o bajo acuerdos de
                confidencialidad); (d) cargar, publicar, enviar por correo electrónico o transmitir cualquier material
                que incluya virus de software o cualesquier otros códigos informáticos, archivos, gusanos, bombas
                lógicas o programas diseñados para interrumpir, inhabilitar, dañar, destruir o limitar la funcionalidad
                del Sitio o cualquier software o hardware o equipo de telecomunicaciones o cualquier actividad
                destructiva similar; (e) obtener acceso no autorizado a sistemas, datos, contraseñas u otro tipo de
                información; (f) interferir o interrumpir el Sitio o los servidores o redes vinculados al Sitio o
                desobedecer los requisitos, procedimientos, políticas o regulaciones de las redes vinculadas al Sitio;
                (g) infringir de manera intencional o involuntaria leyes locales, estatales, nacionales o
                internacionales y reglas, regulaciones, órdenes, directivas y similares con validez legal; (h) cargar,
                publicar, enviar por correo electrónico o transmitir material o realizar otras acciones con respecto al
                uso del Sitio que constituyan, o de algún modo alienten, una conducta criminal o una conducta que pueda
                derivar en responsabilidad civil; (i) con fines comerciales, incluidos, entre otros, enviar material
                para solicitar fondos o promover, publicitar o solicitar la venta de bienes o servicios; (j) solicitar a
                otros que se adhieran o participen de servicios comerciales en línea o de grupos u organizaciones fuera
                de línea; (k) suplantar a otra persona o entidad, incluido proporcionar información personal falsa
                (incluido un nombre de usuario falso) o crear una cuenta para otra persona; (l) enviar documentos de
                identificación de otra persona o información financiera confidencial; o (m) infringir la Política de
                privacidad. Las infracciones sobre lo anterior pueden suponer la rescisión inmediata de su licencia de
                acceso o uso del Sitio y pueden ocasionarle sanciones estatales y federales u otras consecuencias
                legales. Nos reservamos el derecho, aunque sin obligación, de investigar el uso que haga del Sitio a fin
                de (1) determinar si se produjo una infracción de los Términos de Uso o (2) cumplir con las leyes,
                regulaciones, procesos legales o requisitos gubernamentales aplicables.

                No controlamos el Contenido de Usuario que se publica en el Sitio y, por ende, no hacemos declaraciones
                ni extendemos garantías en relación con el Contenido de Usuario ni su veracidad, precisión, fiabilidad,
                integridad o calidad. No determinamos si el Contenido de Usuario infringe los derechos de terceros ni
                tenemos control sobre la naturaleza del Contenido de Usuario que usted u otros usuarios podrían
                encontrar ofensiva. El Contenido de Usuario incluye las opiniones, afirmaciones y demás contenidos de
                terceros, no nuestros. Usted reconoce y acepta que la información y las opiniones expresadas por usted y
                otros usuarios incluidas en el Contenido de Usuario que aparece en el Sitio no necesariamente reflejan
                nuestras opiniones ni la de nuestros proveedores de contenido, anunciantes, patrocinadores o entidades
                relacionadas o afiliadas y que nosotros no apoyamos ni respaldamos el Contenido de Usuario ni otro tipo
                de contenido publicado por usted ni por otros usuarios o que de algún modo esté disponible a través del
                Sitio.

            </p><h5 className="font-bold my-4"></h5><p>

            </p><h5 className="font-bold my-4">Nuestro Derecho de Uso del Contenido de Usuario</h5><p>

                Usted no tiene la obligación de enviar Contenido de Usuario al Sitio, pero si elige hacerlo, el material
                no será considerado confidencial ni de propiedad del usuario y podrá ser utilizado por nosotros,
                nuestros afiliados u otros sin restricciones. Usted declara y garantiza que posee o controla todos los
                derechos relacionados con el Contenido de Usuario que envía, y que la publicación y el uso de este
                Contenido de Usuario por nuestra parte no infringe ni viola en modo alguno los derechos de terceros,
                incluidos, entre otros, derechos de privacidad, derechos de publicidad, derechos de autor, derechos
                contractuales, o cualquier otro tipo de derechos de propiedad intelectual o registrada. Al enviar,
                publicar, cargar, modificar o proporcionar información, material o algún otro tipo de comunicación,
                incluido el Contenido de Usuario, haya sido solicitado o no, nos concede a nosotros y nuestros delegados
                el derecho y la licencia totalmente pagada, exenta de regalías, sin restricciones, global, perpetua,
                irrevocable, no exclusiva y completamente transferible, subrogable, transferible a terceros para usar
                copiar, reproducir, modificar, adaptar, publicar, traducir, crear material derivado, mejorar,
                distribuir, comercializar, ejecutar o mostrar dicho Contenido de Usuario (en forma total o parcial) en
                todo el mundo y/o incorporarlo en otros trabajos cualquiera sea la forma, el medio o la tecnología
                conocida actualmente o posteriormente desarrollada para cualquier fin, incluidos, entre otros, fines
                promocionales o publicitarios, de reproducción, transmisión, publicación, emisión sin consentimiento
                adicional de su parte y sin ningún crédito, notificación y/o compensación a su persona ni a terceros.
                Por la presente, usted también concede a cada usuario del Sitio una licencia no exclusiva para tener
                acceso al Contenido de Usuario a través del Sitio y para usar, editar, modificar, reproducir,
                distribuir, preparar trabajos derivados, mostrar y ejecutar dicho Contenido de Usuario. Nosotros y
                nuestros delegados también tenemos el derecho, pero no la obligación, de usar su nombre de usuario (y
                nombre real, imagen u otra información identificativa, de ser proporcionada, relacionada con el
                Contenido de Usuario), ciudad y estado con respecto a la transmisión, impresión, uso en línea u otro
                tipo de uso o publicación de su Contenido de Usuario. Nosotros y todos nuestros delegados podemos usar o
                transferir, eliminar o disponer de la totalidad o parte del Contenido de Usuario sin restricciones, y
                los usuarios del Sitio no tendrán derecho a recibir ninguna compensación por el uso, la transferencia o
                la disposición del Contenido de Usuario por parte nuestra. No obstante lo anterior, los datos que
                permitan la identificación de la persona, si los hubiera, incluidos en el Contenido de Usuario serán
                tratados de conformidad con nuestra Política de Privacidad.

                Nosotros utilizamos el Contenido de Usuario para: satisfacer sus solicitudes de productos o servicios;
                administrar su cuenta; enviarle comunicaciones de mercadeo que pueden adaptarse a su perfil en función
                de la información personal que conocemos de Usted y sus preferencias; proporcionar contenido, servicios,
                anuncios y ofertas personalizadas, incluso en función de sus características y preferencias de belleza;
                publicar su contenido y reseñas en el Sitio Web, nuestras páginas de redes sociales y sitios de terceros
                relacionados que venden al por menor o presentan nuestras marcas y productos; administrar cualquier
                competencia, promoción, encuesta, sorteo, obsequio o concurso en el que decida participar; gestionar,
                mejorar y desarrollar nuestro negocio; verificar su identidad y detectar y prevenir fraudes, y mantener
                la seguridad de nuestros productos, Sitio Web y sistemas; comunicarnos con Usted, responder preguntas e
                interactuar con Usted de otro modo, y crear mejores experiencias; proporcionarle los servicios
                solicitados o completar las transacciones que solicite; recopilar, evaluar y monitorear informes de
                eventos no deseados durante o después del uso de Nuestros productos; y respaldar nuestras operaciones
                diarias, incluso para cumplir con los requisitos legales, de cumplimiento y de riesgo.

            </p><h5 className="font-bold my-4">Transmisión de Materiales</h5><p>

                Usted comprende que el procesamiento técnico y la transmisión del Sitio pueden incluir (a) transmisiones
                a través de varias redes y (b) cambios a fin de conformar y adaptar a los requerimientos técnicos de
                dispositivos o redes de conexión. No asumimos responsabilidad alguna por la eliminación o falla en el
                almacenamiento de las publicaciones o de otra información enviada por usted u otros usuarios del Sitio.

                Usted acepta que no recolectaremos, recopilaremos ni almacenaremos información sobre los usuarios del
                Sitio ni Contenido de Usuario ni utilizaremos dicha información para fines que estén en contradicción
                con los fines del Sitio ni para transmitir ni facilitar la transmisión de publicidad no solicitada,
                comunicaciones o correo electrónico no deseado. Usted no puede: (i) realizar acciones que impongan o
                puedan imponer (según lo determinemos nosotros a nuestra total discreción) una carga de tamaño
                desproporcionado o irracional en nuestra infraestructura; (ii) interferir o intentar interferir en el
                correcto funcionamiento del Sitio o de las actividades que se llevan a cabo en el Sitio; (iii) ignorar
                las medidas que podamos utilizar a fin de prevenir o restringir el acceso al Sitio o a parte del mismo
                (o a otras cuentas, sistemas informáticos o a redes conectadas con el Sitio); (iv) ejecutar cualquier
                forma de respuesta automática o «correo electrónico no deseado» en el Sitio; (v) usar software manual o
                automático, dispositivos u otros procesos que «recorran» o «busquen con arañas web» páginas del Sitio;
                ni (vi) recolectar o extraer contenido del Sitio.

            </p><h5 className="font-bold my-4">Disponibilidad de </h5><p></p><h5
                className="font-bold my-4">Productos</h5><p>

                La disponibilidad de los productos y servicios que se describen en el Sitio y las descripciones de estos
                productos y servicios pueden variar según el lugar y el momento donde dichos productos y servicios estén
                disponibles. Considerando que los productos o servicios no están a la venta en el Sitio, L’Oreal no se
                hace responsable de la falta de disponibilidad de dichos productos y servicios en el lugar y el momento
                en el que usted decida adquirirlos.

            </p><h5 className="font-bold my-4">Derechos de Propiedad Intelectual</h5><p>

                El Sitio y todos sus contenidos, incluidos, entre otros, artículos, otros textos, fotografías, imágenes,
                ilustraciones, gráficos, material en video, material de audio (incluidas composiciones musicales y
                grabaciones de sonido), software, logotipos, títulos, personajes, nombres, íconos de botones y gráficos,
                a excepción del Contenido de Usuario (en conjunto, «Material Propietario»), están protegidos por
                derechos de autor, marca comercial y otras leyes de los Estados Unidos y Puerto Rico, como también por
                convenciones internacionales y leyes de otros países. El Material Propietario es propiedad o está
                controlado por Nosotros o por otras partes que han otorgado derechos a L'Oréal o a algunas de sus
                entidades relacionadas.

                Excepto que se estipule lo contrario en estos Términos de Uso o sin nuestro permiso previo expreso por
                escrito, no podrá, y acepta no hacerlo, usar, publicar, reproducir, mostrar, ejecutar públicamente,
                crear trabajos derivados, aplicar ingeniería inversa, descompilar, desmontar, distribuir, otorgar
                licencia, transferir, vender, copiar, ingresar en bases de datos, subir, transmitir o modificar el
                Material Propietario ni partes del mismo, por ningún motivo ni a través de ningún medio, método o
                proceso conocido actualmente o desarrollado posteriormente. La modificación del material que aparece en
                el Sitio o el uso de dicho material con otros fines constituyen una infracción de los derechos de autor
                y otros derechos de propiedad intelectual.

            </p><h5 className="font-bold my-4">Rescisión de la </h5><p></p><h5 className="font-bold my-4">Cuenta</h5><p>

                Podemos, en circunstancias adecuadas, rescindir su cuenta en el Sitio y cualesquiera o todos los sitios
                web de nuestros afiliados en caso de que sea un infractor recurrente. Si cree que un usuario es un
                infractor recurrente, siga las instrucciones anteriores para comunicarse con nuestro Agente de Derechos
                de Autor y proporcionar suficiente información a fin de que podamos verificar si el usuario es un
                infractor recurrente.

            </p><h5 className="font-bold my-4">Enlaces y Sitios Web de Terceros</h5><p>

                El Sitio proveerá para que pueda vincularse a sitios web, servicios o recursos de terceros en Internet
                desde el Sitio y también que los sitios web, servicios o recursos de terceros contengan enlaces al Sitio
                (en conjunto, «Sitios Vinculados»). No somos responsables del contenido, disponibilidad, publicidad,
                productos, servicios ni de otros materiales de estos Sitios Vinculados, ni de enlaces adicionales
                incluidos allí, y la inclusión que hacemos en los Sitios de Sitios Vinculados no implica nuestro
                respaldo o aprobación del material contenido en los Sitios Vinculados o al que se accede a través de
                estos. En ningún caso seremos responsables, directa ni indirectamente, frente usted u otra persona o
                entidad por pérdidas o daños que surjan o sean ocasionados por la creación o el uso de los Sitios
                Vinculados o la información o el material al que se accede a través de estos Sitios Vinculados.

            </p><h5 className="font-bold my-4">Anuncios Publicitarios, Patrocinios, Promociones Conjuntas y Otras
                Asociaciones</h5><p>

                Es posible que mostremos anuncios publicitarios de bienes y servicios de un tercero en el Sitio, incluso
                relacionados con promociones conjuntas, patrocinios y otros acuerdos de asociaciones similares. No
                respaldamos ni representamos a dichos bienes o servicios anunciados ni somos responsables de su
                seguridad, calidad, precisión, fiabilidad, integridad o legalidad.

            </p><h5 className="font-bold my-4">Concursos</h5><p>

                En este Sitio puede ocasionalmente haber concursos que ofrezcan premios o requieran que envíe material o
                información sobre su persona. Cada concurso tiene sus propias reglas, que deberá leer y aceptar antes de
                poder participar del mismo.

            </p><h5 className="font-bold my-4">Eventos</h5><p>

                Es posible que lo inviten o le soliciten asistir a eventos que patrocinamos o eventos organizados por
                otros miembros y usuarios del Sitio que no se relacionan de manera alguna con nosotros (en conjunto,
                «Eventos»). Su participación en cualquiera de los Eventos es voluntaria por lo cual usted deberá asumir
                cualquier riesgo que conlleve asistir a dicho Evento.

            </p><h5 className="font-bold my-4">Servicios de Videoconsultas Individuales</h5><p>

                Este Sitio puede dirigirlo a Sitios Vinculados que le permitan utilizar una función de consulta por
                video, ya sea con herramientas automatizadas, una consulta en vivo con una asesora de belleza o ambas
                opciones. Cuando utilice el servicio a través de dicho Sitio Vinculado (el cual tendrá sus propias
                políticas y términos de uso que usted deberá aceptar previo a utilizar sus servicios), acepta permitir
                activar su cámara para las conectarse a las herramientas automatizadas, a la consulta en vivo con una
                asesora de belleza o a ambas. Una consulta por video es una experiencia en vivo y no se grabará.

                Cuando participe en una consulta en vivo con una asesora de belleza, esta puede responder sus preguntas,
                recomendar productos y, cuando estén disponibles, permitirle probar productos de forma virtual durante
                la consulta en vivo. Podrá ver los productos recomendados durante la experiencia y comprarlos una vez
                finalizada la consulta por video. Para ayudarnos a brindarle la mejor experiencia y el mejor
                asesoramiento, a la asesora de belleza se le informará la página que estaba consultando cuando se
                conectó a este servicio.

                Cuando utilice las funciones de consulta por video, acepta regirse por los términos de uso de dicho
                Sitio Vinculado y cumplir con sus disposiciones.

            </p><h5 className="font-bold my-4">Rescisión</h5><p>

                Podemos finalizar o suspender su acceso al Sitio, eliminar su perfil (si alguno) y cualquier contenido o
                información que haya publicado en el Sitio, o prohibir que use o acceda al Sitio (o a una parte, aspecto
                o función del Sitio) por cualquier motivo, o sin ningún motivo, en cualquier momento a nuestro criterio,
                con o sin notificación, con efecto inmediato, lo que puede provocar la pérdida y destrucción de toda la
                información relacionada con usted y sus actividades en relación con el Sitio. Si desea cerrar su cuenta,
                puede hacerlo según las instrucciones que aparecen en el Sitio. Todos los pagos efectuados de
                conformidad con el presente son no reembolsables. En caso de rescisión, seguirá estando sujeto a sus
                obligaciones conforme a estos Términos de Uso.

            </p><h5 className="font-bold my-4">Información General</h5><p>

                Nosotros controlamos y operamos el Sitio desde nuestras oficinas en el Estado Libre Asociado de Puerto
                Rico. Si bien invitamos a personas de todas partes del mundo a visitar el Sitio, los visitantes
                reconocen que el Sitio, y todas las actividades disponibles en él o a través de él, está regido por las
                leyes de los Estados Unidos de América Estado Libre Asociado de Puerto Rico. No declaramos que los
                materiales del Sitio sean adecuados o estén disponibles para su uso en otros lugares. Las personas que
                deciden acceder al Sitio desde otros lugares lo hacen bajo su propia iniciativa y son responsables de su
                cumplimiento con las leyes locales.

                Usted acepta que las leyes del Estado Libre Asociado de Puerto Rico, con exclusión de sus normas de
                conflicto jurisdiccional, y estos Términos de Uso, nuestra Política de Privacidad y cualesquiera
                políticas publicadas ocasionalmente en el Sitio aplicables a su uso del Sitio regirán su uso del Sitio.
                Tenga en cuenta que su uso del Sitio puede estar sujeto a otras leyes locales, estatales, nacionales e
                internacionales. Usted acepta expresamente que la jurisdicción exclusiva para cualquier reclamación o
                disputa con nosotros (o con cualquiera de nuestros afiliados) o que se relacione de cualquier modo con
                su uso del Sitio reside en los tribunales del Estado Libre Asociado de Puerto Rico, y además acepta y
                otorga su consentimiento expreso para ejercer la jurisdicción personal en los tribunales del Estado
                Libre Asociado de Puerto Rico, con relación a dicha disputa, incluida cualquier reclamación que nos
                involucre a nosotros o a nuestros afiliados, sucursales, empleados, contratistas, funcionarios,
                directores, proveedores de telecomunicaciones y proveedores de contenido.

            </p><h5 className="font-bold my-4">Arbitraje</h5><p>

                Al hacer uso de este Sitio, usted acepta que L’Oréal o alguna de sus afiliadas exija a su exclusiva
                discreción que: (1) cualquier tipo de disputa o causa de acción relacionada o conectada con los
                presentes Términos, el Sitio, y las ofertas relacionadas deberán resolverse de manera individual sin
                recurrir a ninguna forma de demanda colectiva, y exclusivamente mediante arbitraje final y vinculante
                bajo las reglas de la American Arbitration Association en la oficina regional de la AAA más cercana al
                participante; (2) el Federal Arbitration Act deberá regir la interpretación, aplicación y todos los
                procedimientos de dicho arbitraje; y (3) un juicio sobre dicho laudo arbitral podrá ser introducido en
                cualquier corte que tenga jurisdicción. Usted comprende y acepta que renuncia a su derecho de demandar o
                ir a juicio para reivindicar o defender sus derechos bajo este contrato.

                Se admitirá una versión impresa de los Términos de Uso y de cualquier aviso proporcionado en formato
                electrónico para los procedimientos judiciales o administrativos en función de los Términos de Uso o en
                relación con estos en la misma medida y sujetos a las mismas condiciones que otros documentos
                comerciales y registros generados originalmente y conservados en formato impreso.

                Estos Términos de Uso constituyen el contrato completo entre usted y nosotros con respecto al Sitio y
                cualquier Contenido de Usuario, y reemplaza a todas las comunicaciones y propuestas (ya sean orales,
                escritas o electrónicas) anteriores o contemporáneas con respecto a estas cuestiones. Si se determina
                que alguna disposición de estos Términos de Uso es ilegal o queda sin efecto, las disposiciones
                restantes no se verán afectadas y continuarán siendo completamente válidas, vinculantes y ejecutables.
                El hecho de no ejercer cualquiera de los derechos dispuestos en el presente por parte de alguna de las
                partes no se considerará una renuncia de otros derechos que surjan conforme al presente. No se crea
                ninguna agencia, sociedad, sociedad conjunta o relación laboral como consecuencia de estos Términos de
                Uso, y ninguna de las partes tiene autoridad de ningún tipo para obligar a la otra en ningún aspecto.

            </p><h5 className="font-bold my-4">Avisos</h5>

                <p>Salvo que se especifique lo contrario en estos Términos de Uso, todos los avisos conformes a estos
                    Términos de Uso se harán por escrito y se considerarán debidamente otorgados cuando se reciban, si
                    se entregaron personalmente o se enviaron por correo certificado o con acuse de recibo; cuando se
                    confirme su recepción por vía electrónica, si se transmitieron por fax o correo electrónico; o el
                    día después de enviados si se enviaron con entrega al día siguiente a través de un servicio de
                    entregas nocturnas reconocido.

                    No dude en ponerse en contacto con nosotros a través de <a
                        href="mailto:DMCA@loreal.com">DMCA@loreal.com</a>. Usted acepta que ocasionalmente le enviemos
                    notificaciones respecto a su uso del Sitio mediante correo electrónico, la publicación de una nota
                    general en el Sitio, o mediante el envío de un comunicado escrito entregado por correo de entrega
                    inmediata o el correo de EE. UU. A su dirección de correo electrónico o a su dirección postal tal
                    como aparece en nuestros registros.

                    Última actualización de estos Términos de Uso – Febrero 2024.<br/><br/></p>
            </div>
            <Brands data={json.brands} pr={project === "pr"}/>
            <Footer data={json.footer}/>
        </div>
    )
}


export default TermsAndConditions;

