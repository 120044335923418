import React from "react";
import {BannerData} from "../../interfaces/BannerData";

export function Banner({data, pr}: { data: BannerData, pr: boolean }) {
    return (
        <div className="h-[30rem]" style={{
            backgroundImage: `url(${data.imagen})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "none"
        }}>
            <div
                className="absolute w-full h-[30rem] bg-gradient-to-b from-black opacity-75 mix-blend-mode-multiply"> {/* Gradient background */}
            </div>
            <div
                className="md:pt-60 md:px-60 text-white w-9/12 z-20 relative"> {/* Title and description content (adjusted with z-index) */}
                <div className="text-6xl hidden md:block font-Playfair">{data.tittle}</div>
                <div className="text-6xl hidden md:block font-Playfair">{data.subTittle}</div>
                <div
                    className="block md:hidden text-2xl text-left ml-4 pt-80 font-semibold font-Playfair">{data.tittleMobile}</div>
                {pr && <a href={data.url} target="_blank"
                          className="block md:hidden bg-black w-fit p-2 ml-4 mt-2">Aquí</a>}
                <div className={`md:text-2xl md:mt-4 ${pr ? "hidden md:block" : "text-lg ml-4 mt-auto"}`}>{data.description}</div>
            </div>
        </div>
    )
}